import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { prepareRequest } from '@proman/utils';
import { EditiableParameterInterface, EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';
import { AccountCategory, Payment } from '@proman/interfaces/entity-interfaces';
import { EntityInterface } from '@proman/services/entity.service';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'pm-payment-dialog',
    template: `
        <pro-dialog-title [title]="variant"></pro-dialog-title>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="Padding-8">

            <div fxLayout="column" fxFlex="40">
                <pro-checkbox [value]="payment?.recordDbit?.active || payment?.recordCrdt?.active"
                              (onChange)="updateActive($event)"
                              [config]="{ label: 'active' }"></pro-checkbox>

                <pm-price [value]="payment.sum"
                          [config]="{ label: 'sum' }"
                          (onChange)="payment.sum = $event"></pm-price>

                <pm-price [value]="payment.sum"
                          [config]="{ label: 'sum' }"
                          (onChange)="payment.sum = $event"></pm-price>
            </div>
            <br>

            <div fxLayout="column" fxFlex="60">

                @if (payment?.recordCrdt) {
                <div fxLayout="row">
                    <pm-account-category-type-btn fxFlex="70px"
                            [value]="payment.recordCrdt?.type"
                            (onChange)="payment.recordCrdt.type = $event"></pm-account-category-type-btn>

                    <pro-autoc fxFlex
                               [value]="payment.recordCrdt?.accountCategory"
                               [config]="{ label: 'category', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }"
                               (onChange)="payment.recordCrdt.accountCategory = $event; payment.recordCrdt.type = $event.type">
                    </pro-autoc>
                </div>
                }

                @if (payment?.recordDbit) {
                    <div fxLayout="row">
                        <pm-account-category-type-btn fxFlex="70px"
                                                      [value]="payment.recordDbit?.type"
                                                      (onChange)="payment.recordDbit.type = $event"></pm-account-category-type-btn>

                        <pro-autoc fxFlex
                                   [value]="payment.recordDbit?.accountCategory"
                                   [config]="{ label: 'category', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }"
                                   (onChange)="payment.recordDbit.accountCategory = $event;payment.recordDbit.type = $event.type">
                        </pro-autoc>
                    </div>
                }

            </div>

        </div>

        <div mat-dialog-content fxLayout="column">
            @for (parameter of parameters; track $index) {
                <pro-parameter [parameter]="parameter"
                               [config]="parameter.config"
                               [disabled]="parameter.disabled"
                               (onChange)="set(parameter.key, $event)"></pro-parameter>
            }
        </div>

        <pro-dialog-actions (callback)="create()" [variant]="variant"></pro-dialog-actions>
    `
})

export class PaymentDialogComponent {
    payment: Payment;
    form: UntypedFormGroup;
    controls: any = {};
    parameters: EditiableParameterInterface[];
    variant: "create" | "remove" | "confirm" | "save" | "edit" | "update";
    entityInterface: EntityInterface;
    glrEntity: EntityInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<PaymentDialogComponent>,
        private Entity: Entity,
    ) {

        const validators: any = ['', Validators.required];

        const mainField: EntityItemFieldConfig = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: {
                required: true,
                control: new UntypedFormControl(validators),
            }
        };

        this.glrEntity = this.Entity.get('general_ledger_record');
        this.payment = Object.assign({}, mainField, this.data.payment);
        this.variant = this.data.variant ?? 'confirm';
        this.entityInterface = this.data.entityInterface
        this.setParameters();

        if (this.data.parameters) {
            this.parameters = [].concat(this.parameters, this.data.parameters);
        }

        for (const parameter of this.parameters) {

            if (this.data.item) parameter.value = this.data.item[parameter.key];

            if (parameter.config.control) {
                this.controls[parameter.key] = parameter.config.control;
            }

            if (parameter.config.required && !this.controls[parameter.key]) {
                const control = new UntypedFormControl(parameter.value || '', Validators.required);
                this.controls[parameter.key] = control;
                parameter.config.control = control;
            }

            if (parameter.value) {
                this.set(parameter.key, parameter.value);
            }

            if (parameter.type === 'string') {
                parameter.config = Object.assign({ debounce: 0 }, parameter.config);
            }
        }

        this.form = new UntypedFormGroup(this.controls);

    }

    setParameters = () => {
        this.parameters = [
            {
                key: 'date',
                name: 'date',
                type: 'date',
                config: { hideTime: true },
                value: this.payment.date
            },
            {
                key: 'bankTransaction',
                name: 'bank_transaction',
                type: 'autocomplete',
                config: { entity: 'bank_transaction', searchFields: ['transactionId', 'id'], displayKey: 'transactionId' },
                value: this.payment.bankTransaction
            }
        ];
    }

    create() {
        this.confirm().then(() => {
            const data = prepareRequest(this.payment);
            this.dialogRef.close(data);
        });
    }

    getCallback(button: any) {
        if (this.form.valid) {
            const data = prepareRequest(this.payment);
            this.dialogRef.close();
            button.callback(data);
        }
    }

    getCategoryName = (cat: AccountCategory) => cat ? `${cat.keyName} - ${cat.name}` : '';

    set(property: string, value: any) {
        this.payment[property] = value;
    }

    updateActive = (active: boolean) => {
        this.glrEntity.update({ id: this.payment.recordDbit.id, active });
        this.glrEntity.update({ id: this.payment.recordCrdt.id, active });
    }

    async confirm() {
        await this.entityInterface.update({
            id: this.payment.id,
            sum: this.payment.sum,
            date: this.payment.date,
            bankTransaction: this.payment.bankTransaction?.id
        });
        await this.glrEntity.update({
            id: this.payment.recordCrdt?.id,
            type: this.payment.recordCrdt?.type,
            accountCategory: this.payment.recordCrdt?.accountCategory?.id,
        });
        await this.glrEntity.update({
            id: this.payment.recordDbit?.id,
            type: this.payment.recordDbit?.type,
            accountCategory: this.payment.recordDbit?.accountCategory?.id,
        });
    }

}
